<template>
  <v-container>
    <Patcher
      :itemValue="itemData.group_id"
      itemName="group_id"
      itemLabel="Gruppe"
      :route="patchRoute + itemData.id"
      inputElement="select"
      inputType=""
      :selectOptions="itemData.groups"
      :previewValue="idToName(itemData.group_id, itemData.groups)"
      hint=""
      selectValueKey="id"
      selectTextKey="name"
      @emit="patched($event.value)"
    ></Patcher>
    <p class="red--text">
      ACHTUNG: Aktuell werden Gruppen noch nicht beachtet. Alle Kunden sehen
      ALLE Meldungen!
    </p>

    <Patcher
      :itemValue="itemData.mediaUuid"
      itemName="mediaUuid"
      itemLabel="Foto"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement=""
      inputType="media"
      :previewValue="itemData.mediaUuid"
      @emit="itemData.mediaUuid = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.title"
      itemName="title"
      itemLabel="Titel"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.title"
      @emit="itemData.title = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.isFeatured"
      itemName="isFeatured"
      :route="patchRoute + itemData.id"
      inputElement="switch"
      inputType=""
      :previewValue="itemData.isFeatured"
      itemLabel="Featured"
      @emit="itemData.isFeatured = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.exerpt"
      itemName="exerpt"
      itemLabel="Vorschautext"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.exerpt"
      @emit="itemData.exerpt = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.content"
      itemName="content"
      itemLabel="Inhalt"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.content"
      @emit="itemData.content = $event.value"
    ></Patcher>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "News",

  components: {
    Patcher,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "ffnews/",
      fetchingData: false,
    };
  },

  methods: {
    patched(value) {
      this.itemData.group_id = value;
      this.$emit("updated");
    },

    idToName(id, array) {
      if (id === null) {
        return "";
      }
      const item = array.find((item) => item.id === id);
      return item.name;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
